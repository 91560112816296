//import {numberFormats} from './i18n/number-formats'
//import {dateTimeFormats} from './i18n/date-time-formats'

export default defineI18nConfig(() => ({
  fallbackLocale: 'en',
  //numberFormats: numberFormats,
  //dateTimeFormats: dateTimeFormats,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  legacy: true
}))
